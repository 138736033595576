import { IBranchOffice } from "../interfaces/BranchOffice.interface";

export const convertData_Adapter = (data: any[]) => {
  const result: any[] = [];

  data.forEach((x) => {
    result.push(getRow(x));
  });

  return result;
};

const getRow = (row: any) => {
  return { ...row, title: { display: row.title, url: "#" } };
};
