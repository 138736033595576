import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Messages from "../../Messages/index.component";

export interface IOption {
  value: string;
  display: string;
}
interface Props {
  label: string;
  _key: string;
  name: string;
  value: string;
  change: (value: any) => void;
  alertMessage?: string;
  options: IOption[];
  disable?: boolean;
}

const SelectInput = ({
  label,
  _key,
  name,
  value,
  change,
  alertMessage,
  options,
  disable,
}: Props) => {
  return (
    <Grid item container direction={"column"} gap={0.5}>
      <Grid>
        <FormControl key={`${_key}_formControl`} sx={{ width: "100%" }}>
          <InputLabel id={name}>{label}</InputLabel>
          <Select
            key={_key}
            labelId={`${name}-label`}
            id={name}
            value={value}
            onChange={change}
            label={label}
            disabled={disable}
          >
            <MenuItem key={`${_key}_formControl_-1`} value="">
              <em>Seleccionar</em>
            </MenuItem>
            {options.map((_option: IOption, index: number) => (
              <MenuItem
                key={`${_key}_formControl_${index}`}
                value={_option.value}
              >
                {_option.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {(alertMessage || "") !== "" && (
        <Grid>
          <Messages message={alertMessage} type="alert" />
        </Grid>
      )}
    </Grid>
  );
};

export default SelectInput;
