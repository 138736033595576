import { IOption } from "../../Components/inputs/SelectInput/index.component";
import { IFederalEntity } from "./useFederalEntity";

export const FederalEntityAdapter = (data: IFederalEntity[]): IOption[] => {
  const result: IOption[] = [];

  data.map((x) =>
    result.push({
      value: x.ID,
      display: x.name,
    })
  );

  return result;
};
