import { useState } from "react";
import { IProperty } from "../../../Components/inputs/TableInput/index.component";
import { _Headers, _Properties, dataMock } from "../utils/BranchOffice.mock";
import { BranchOfficeAdapter } from "../adapters/branchOffice.adapter";

export const useBranchOffice = () => {
  const title: string = "Sucursales";
  const [headers, setHeaders] = useState<string[]>(_Headers);
  const [properties, setProperties] = useState<IProperty[]>(_Properties);
  const [data, setData] = useState<any[]>(BranchOfficeAdapter(dataMock));
  const handeNew = () => {};
  const handeEdit = (value: any) => {};
  const handeRefresh = () => {};

  return {
    title,
    headers,
    data,
    setData,
    handeNew,
    handeEdit,
    handeRefresh,
    properties,
  };
};
