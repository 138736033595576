import { useCallback, useEffect, useState } from "react";
import {
  INTERNAL_MESSAGES,
  useApiRequest,
} from "../../../Hooks/ApiRequest/ApiRequest.component";
import { IOption } from "../../../Components/inputs/SelectInput/index.component";

interface ItemResponse {
  value: string;
  display: string;
}

export const usePermissions = <Request, response>() => {
  const [response, setResponse] = useState<IOption[] | undefined>([]);
  const [msgError, setMsgError] = useState("");

  const Client = useApiRequest<
    {},
    { rowCount: number; page: number; data: ItemResponse[] }
  >({ path: "user/GetPermision", method: "GET" });

  const execute = useCallback(() => {
    Client.execute();
  }, []);

  useEffect(() => {
    if (Client.status === "success") {
      setMsgError("");
      const result: IOption[] = [];
      Client.value?.data?.data.forEach((x: ItemResponse) => {
        result.push({ display: x.display, value: x.value } as IOption);
      });

      setResponse(result);
    }
    if (Client.status === "error") {
      setMsgError(Client.value?.message || INTERNAL_MESSAGES.ERROR_MESSAGE);
      setResponse(undefined);
    }
  }, [Client.status]);

  return { execute, msgError, response };
};
