import { IProperty } from "../../../Components/inputs/TableInput/index.component";
import { IBranchOffice } from "../interfaces/BranchOffice.interface";

export const dataMock: IBranchOffice[] = [
  {
    ID: "1",
    branchOfficeName: "TUXTLA GUTIERREZ, CHIAPAS",
    address: "CALLEJON BRASILITO No.656-A COL. EL BRASILITO",
    location: ["TUXTLA GUTIERREZ"],
    city: "TUXTLA GUTIERREZ",
    state: "CHIAPAS",
    cp: "29040",
  },
  {
    ID: "2",
    branchOfficeName: "TAPACHULA",
    address: "No.",
    location: ["TUXTLA GUTIERREZ"],
    city: "",
    state: "",
    cp: "29000",
  },
];

export const _Headers: string[] = [
  "ID",
  "Sucursal",
  "Dirección",
  "Localidad",
  "Ciudad",
  "Estado",
  "C.P.",
];
export const _Properties: IProperty[] = [
  { name: "ID", type: "text" },
  { name: "branchOfficeName", type: "text" },
  { name: "address", type: "text" },
  { name: "location", type: "label" },
  { name: "city", type: "text" },
  { name: "state", type: "text" },
  { name: "cp", type: "text" },
];
