import TableInput from "../../../Components/inputs/TableInput/index.component";
import { Headers, Properties, dataMock } from "../utils/TaxData.Utilities";

interface Props {
  title: string;
}

const TaxData = ({ title }: Props) => {
  return (
    <>
      <TableInput
        Title={title}
        headers={Headers}
        properties={Properties}
        data={dataMock}
        handleNew={() => {}}
        handleEdit={() => {}}
        handleRefresh={() => {}}
        hiddenTitle={true}
        hiddenRefreshButton
        hiddenNewButton
        hiddenSearchInput
        hiddenActionsColumn
        hiddenHeader
        paddingNumber={1}
        hiddenPagination
      />
      {/* <CustomModal
        Title={tittleModal}
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        width={600}
      >
        <Project_Create />
      </CustomModal> */}
    </>
  );
};

export default TaxData;
