import { InputInterface } from "../../Components/inputs/DynamicInputs/index.component";

export const inputsCreateJSON: InputInterface[] = [
  //disable?: boolean;
  //required?: boolean;
  //helperText?: string;
  {
    name: "ID",
    type: "text",
    label: "Reseller: ",
  },
  {
    name: "User",
    type: "text",
    label: "Nombre de Usuario:",
  },
  {
    name: "Password",
    type: "password",
    label: "Contraseña:",
  },
  {
    name: "Name",
    type: "text",
    label: "Nombre:",
  },
  {
    name: "Email",
    type: "text",
    label: "Correo Electrónico:",
  },
  {
    name: "TypeUser",
    type: "autocomplete",
    label: "Tipo:",
  },
  {
    name: "SystemId",
    type: "text",
    label: "Id Sistema:",
  },
  {
    name: "System",
    type: "text",
    label: "Sistema:",
  },
  {
    name: "systemType",
    type: "text",
    label: "Tipo de Sistema:",
  },
  {
    name: "Sucursal",
    type: "autocomplete",
    label: "Sucursal:",
    options: [],
  },
  {
    name: "references",
    type: "text",
    label: "Referencia",
  },
  {
    name: "BD",
    type: "text",
    label: "BD:",
  },
];
export const inputsCreate_PermissionsJSON: InputInterface[] = [
  {
    name: "Modules",
    type: "multiselect",
    label: "Permisos:",
  },
];
