import { InputInterface } from "../../Components/inputs/DynamicInputs/index.component";
import { IProperty } from "../../Components/inputs/TableInput/index.component";

export const Properties: IProperty[] = [
  { name: "ID", type: "text" },
  { name: "folio", type: "text" },
  { name: "businessName", type: "text" },
  { name: "RFC", type: "text" },
  { name: "address", type: "text" },
  { name: "contact", type: "text" },
];

export const Headers: string[] = [
  "ID",
  "Clave",
  "Razón Social",
  "RFC",
  "Dirección",
  "Contacto",
];

export const inputs: InputInterface[] = [
  {
    name: "businessName",
    type: "text",
    label: "Razón Social:",
  },
  {
    name: "RFC",
    type: "text",
    label: "R.F.C.:",
  },
  {
    name: "email",
    type: "text",
    label: "Email:",
  },

  {
    name: "taxRegime",
    type: "select",
    label: "Regimen Fiscal:",
    options: [
      {
        value: "1",
        display: "Prueba 1",
      },
      {
        value: "2",
        display: "Prueba 2",
      },
    ],
  },
  {
    name: "CP",
    type: "text",
    label: "C.P.:",
  },
  {
    name: "printKey",
    type: "check",
    label: "Imprimir Clave",
  },
  {
    name: "phone",
    type: "text",
    label: "Teléfono:",
  },
  {
    name: "cellPhone",
    type: "text",
    label: "Celular:",
  },
  {
    name: "fax",
    type: "text",
    label: "Fax:",
  },
  {
    name: "street",
    type: "text",
    label: "Calle:",
  },
  { name: "externalNumber", type: "text", label: "No. Exterior:" },
  { name: "interiorNumber", type: "text", label: "No. Interior:" },
  { name: "Reference", type: "text", label: "Referencia:" },
  { name: "Cologne", type: "text", label: "Colonia:" },
  { name: "Delegation", type: "text", label: "Localidad o Delegacion:" },
  { name: "City", type: "text", label: "Ciudad o Municipio:" },
  { name: "State", type: "text", label: "Estado:" },
  { name: "Country", type: "text", label: "Pais:" },
  { name: "Observations", type: "text", label: "Observaciones:" },
  { name: "creditDays", type: "text", label: "Días Crédito" },
  { name: "creditAmount", type: "text", label: "Monto Crédito" },
  {
    name: "defaultPrice",
    type: "select",
    label: "Precio predeterminado",
    options: [
      { value: "1", display: "Precio 1" },
      { value: "2", display: "Precio 2" },
      { value: "3", display: "Precio 3" },
      { value: "0", display: "Precio Producción" },
    ],
  },
];
