import { ITransmitterFolio } from "../interfaces/TransmitterFolio.interface";

export const TransmitterFolioAdapter = (data: ITransmitterFolio[]) => {
  const result: any[] = [];

  data.map((row: ITransmitterFolio) => {
    result.push({ ...row });
  });

  return result;
};
