import { Button, Grid, TableFooter, TablePagination } from "@mui/material";
import { PageTitle } from "../../../../_metronic/layout/core";
import AddIcon from "@mui/icons-material/Add";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { KTSVG } from "../../../../_metronic/helpers";
import TableInput_Label, {
  ITableInput_Label,
} from "./components/label.component";
import { useState } from "react";
import TableInputSearch from "./components/search.component";
import TableInput_buttons from "./components/buttons.component";

interface Props {
  Title: string;
  Subtitle?: string;
  handleNew?: any;
  handleRefresh?: any;
  handleEdit?: any;
  headers: string[];
  properties: IProperty[];
  data: any;
  hiddenTitle?: boolean;
  hiddenNewButton?: boolean;
  hiddenRefreshButton?: boolean;
  hiddenSearchInput?: boolean;
  hiddenActionsColumn?: boolean;
  hiddenHeader?: boolean;
  hiddenPagination?: boolean;
  paddingNumber?: number;
}

export interface IProperty {
  name: string;
  type: "text" | string;
}

const TableInput = ({
  Title,
  Subtitle,
  handleNew,
  handleRefresh,
  handleEdit,
  headers,
  properties,
  data,
  hiddenTitle = false,
  hiddenNewButton,
  hiddenRefreshButton,
  hiddenSearchInput = false,
  hiddenActionsColumn = false,
  hiddenHeader = false,
  hiddenPagination = false,
  paddingNumber = 9,
}: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  function GetValueText(data: any, propertyName: string): string {
    let newValue: string = "";

    if (Object.prototype.hasOwnProperty.call(data, propertyName)) {
      newValue = data[propertyName].toString() || "";
    }

    return newValue;
  }
  function GetValueLink(
    data: any,
    propertyName: string
  ): { display: string; url: string } {
    let newValue: { display: string; url: string } = {
      display: "",
      url: "",
    };

    if (Object.prototype.hasOwnProperty.call(data, propertyName)) {
      newValue = data[propertyName] || {
        display: "",
        url: "",
      };
    }

    return newValue;
  }
  function GetValueModules(
    data: any,
    propertyName: string
  ): ITableInput_Label[] {
    let newValue: ITableInput_Label[] = [];

    if (Object.prototype.hasOwnProperty.call(data, propertyName)) {
      newValue = data[propertyName] || [];
    }

    return newValue;
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}></PageTitle>
      <Grid className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <Grid className={`card-body p-${paddingNumber}`}>
          {hiddenHeader !== true && (
            <Grid container item direction={"row"} columns={12}>
              <Grid container item xs={6} direction={"column"}>
                <Grid item>
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">
                      {hiddenTitle ? "" : Title}
                    </span>
                  </h3>
                </Grid>
                {Subtitle && (
                  <Grid item>
                    <h3 className="card-title align-items-start flex-column">
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {Subtitle}
                      </span>
                    </h3>
                  </Grid>
                )}
              </Grid>
              <Grid container item direction={"column"} xs={6} gap={1}>
                <TableInput_buttons
                  handleNew={handleNew}
                  handleRefresh={handleRefresh}
                  hiddenNewButton={hiddenNewButton}
                  hiddenRefreshButton={hiddenRefreshButton}
                />
                {hiddenSearchInput !== true && (
                  <TableInputSearch name={Title.replaceAll(" ", "_")} />
                )}
              </Grid>
            </Grid>
          )}

          <Grid container item className="mt-5">
            <table
              style={{ width: "100%" }}
              className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
            >
              <thead>
                <tr className="fw-bold text-muted">
                  {hiddenActionsColumn !== true && (
                    <th style={{ width: "50px" }}>Acciones</th>
                  )}
                  {headers.map((_head: string) => (
                    <th hidden={_head === "ID" ? true : false}>{_head}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((_row: any) => (
                  <tr>
                    {hiddenActionsColumn !== true && (
                      <td>
                        <div className="d-flex justify-content-end flex-shrink-0">
                          <a
                            href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                          >
                            <KTSVG
                              path="/media/icons/duotune/communication/com011.svg"
                              className="svg-icon-3"
                            />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                            onClick={() => {
                              handleEdit(_row.ID);
                            }}
                          >
                            <KTSVG
                              path="/media/icons/duotune/art/art005.svg"
                              className="svg-icon-3"
                            />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                          >
                            <KTSVG
                              path="/media/icons/duotune/general/gen027.svg"
                              className="svg-icon-3"
                            />
                          </a>
                        </div>
                      </td>
                    )}
                    {properties.map((_property: IProperty) => (
                      <>
                        {_property.type === "label" && (
                          <td>
                            <TableInput_Label
                              labels={GetValueModules(_row, _property.name)}
                            />
                          </td>
                        )}
                        {_property.type === "text" && (
                          <td hidden={_property.name === "ID" ? true : false}>
                            {GetValueText(_row, _property.name)}
                          </td>
                        )}
                        {_property.type === "image" && (
                          <td hidden={_property.name === "ID" ? true : false}>
                            <img
                              src={GetValueText(_row, _property.name)}
                              style={{ maxHeight: "50px" }}
                            />
                          </td>
                        )}
                        {_property.type === "link" && (
                          <>
                            {GetValueLink(_row, _property.name).url === "" && (
                              <td>{""}</td>
                            )}
                            {GetValueLink(_row, _property.name).url !== "" && (
                              <td
                                hidden={_property.name === "ID" ? true : false}
                              >
                                <a
                                  href={GetValueLink(_row, _property.name).url}
                                >
                                  {GetValueLink(_row, _property.name).display}
                                </a>
                              </td>
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <TableFooter>
              {hiddenPagination !== true && (
                <tr>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    labelRowsPerPage="filas por página"
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={() => {}}
                    onRowsPerPageChange={() => {}}
                  />
                </tr>
              )}
            </TableFooter>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TableInput;
