import { Grid } from "@mui/material";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Card5 } from "../../../../_metronic/partials/content/cards/Card5";

export interface PropsCardServiceOrder {
  image: string;
  id: string;
  codigo: string;
  description: string;
  onClick?: any;
}

export interface PropsServiceOrderForm {
  orders?: PropsCardServiceOrder[];
  onClick?: any;
}
const CardServiceOrder = ({
  image,
  codigo,
  description,
  id,
  onClick,
}: PropsCardServiceOrder) => {
  return (
    <>
      <Grid
        container
        item
        direction={"row"}
        xs={5}
        sm={3}
        md={3}
        lg={2}
        style={{ border: "1px solid #009DF6", borderRadius: "5px" }}
        className={"cursor-pointer"}
        onClick={() => onClick(id)}
      >
        <Grid container item width={"100%"} direction="row">
          <Grid item container direction={"row"}>
            <Grid width={"30px"} className={"symbol symbol-30px"}>
              <img src={toAbsoluteUrl(image)} alt="" className="p-3" />
            </Grid>
            <Grid>
              <span className="fs-4 fw-bold text-hover-primary text-gray-600 m-0">
                {codigo}
              </span>
            </Grid>
          </Grid>
          <Grid item className="px-1">
            <span className="fs-8  text-hover-primary text-gray-600 m-0">
              {description}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const ServiceOrderForm = ({ orders, onClick }: PropsServiceOrderForm) => {
  return (
    <>
      <Grid item container justifyContent={"center"} gap={0.5} columns={15}>
        {orders?.map((order) => (
          <CardServiceOrder
            image={order.image}
            codigo={order.codigo}
            description={order.description}
            id={order.id}
            onClick={onClick}
          />
        ))}
      </Grid>
    </>
  );
};

export default ServiceOrderForm;
