import { Button, Grid } from "@mui/material";
import DynamicInputs from "../../../Components/inputs/DynamicInputs/index.component";
import { useState } from "react";
import { useDigitalStamp } from "../hooks/useDigitalStamp";

interface Props {
  certificate: string;
  validity: string;
}

const DigitalStamp = () => {
  const useDigitalStampHook = useDigitalStamp();

  return (
    <Grid container item direction={"row"} columns={12} gap={0.5}>
      <Grid item container xs={12}>
        <Grid
          container
          item
          style={{
            background: "#45CCB1",
            padding: "9px",
            borderRadius: "9px",
            color: "white",
          }}
          direction={"column"}
          xs={4}
        >
          <Grid>
            <span style={{ fontWeight: "bold", fontSize: 14 }}>
              CERTIFICADO
            </span>
          </Grid>
          <Grid>
            <span>00001000000510828078</span>
          </Grid>
          <Grid>
            <span style={{ fontWeight: "bold", fontSize: 14 }}>VIGENCIA</span>
          </Grid>
          <Grid>
            <span>11-01-2026 11:13:23</span>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <DynamicInputs
          inputs={useDigitalStampHook.inputs}
          columns={1}
          data={useDigitalStampHook.data}
          setData={useDigitalStampHook.setData}
          mode={"edition"}
        />
      </Grid>
      <Grid container item xs={12} justifyContent={"end"}>
        <Button variant="contained" onClick={(e) => {}}>
          Subir Sello
        </Button>
      </Grid>
    </Grid>
  );
};

export default DigitalStamp;
