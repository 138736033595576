import { Grid } from "@mui/material";

interface Props {
  children: JSX.Element;
}

export const LayoutPage = ({ children }: Props) => {
  return (
    <>
      <Grid className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <Grid container item className="m-10" gap={1} justifyContent={"center"}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};
