import TableInput from "../../../Components/inputs/TableInput/index.component";
import { useBranchOffice } from "../hooks/useBranchOffice";

const BranchOffice = () => {
  const useBranchOfficeHook = useBranchOffice();

  return (
    <TableInput
      Title={useBranchOfficeHook.title}
      headers={useBranchOfficeHook.headers}
      properties={useBranchOfficeHook.properties}
      data={useBranchOfficeHook.data}
      handleNew={useBranchOfficeHook.handeNew()}
      handleEdit={(e: any) => useBranchOfficeHook.handeEdit(e)}
      handleRefresh={useBranchOfficeHook.handeRefresh()}
    />
  );
};

export default BranchOffice;
