import { Grid, TextField } from "@mui/material";
import Messages from "../../Messages/index.component";

interface Props {
  label: string;
  name: string;
  value: string;
  change: (value: any) => void;
  alertMessage?: string;
  password?: boolean;
  disable?: boolean;
}

const TextInput = ({
  label,
  name,
  value,
  change,
  alertMessage,
  password,
  disable,
}: Props) => {
  return (
    <Grid item container direction={"column"} gap={0.5}>
      <Grid>
        <TextField
          value={value}
          id={`txt${name}`}
          name={name}
          label={label}
          onChange={(e) => change(e)}
          variant="outlined"
          style={{ width: "100%" }}
          type={password === true ? "password" : undefined}
          disabled={disable}
        />
      </Grid>
      {(alertMessage || "") !== "" && (
        <Grid>
          <Messages message={alertMessage} type="alert" />
        </Grid>
      )}
    </Grid>
  );
};

export default TextInput;
