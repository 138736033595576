import { InputInterface } from "../../Components/inputs/DynamicInputs/index.component";
import { IProperty } from "../../Components/inputs/TableInput/index.component";

export interface dataInfo {
  folio: string;
  cliente: string;
}

export const inputsCreateJSON_0: InputInterface[] = [
  {
    name: "folio",
    type: "select",
    label: "Folio:",
    options: [],
  },
];
export const inputsCreateJSON_1: InputInterface[] = [
  {
    name: "cliente",
    type: "autocomplete",
    label: "Cliente:",
    options: [
      {
        value: "1048",
        display: "C1048- -----",
      },
      {
        value: "1148",
        display:
          "C1148- JESUS ENRQIEU HDZ.-XAXX01010101000-ENRIQUE9097@HOTMAIL.COM",
      },
    ],
  },
];
export const inputsCreateJSON_2: InputInterface[] = [
  {
    name: "contact",
    type: "text",
    label: "Contacto:",
  },
  {
    name: "phone",
    type: "text",
    label: "Teléfono:",
  },
  {
    name: "email",
    type: "text",
    label: "Correo:",
  },
];
export const inputsCreateJSON_3: InputInterface[] = [
  {
    name: "Qualification",
    type: "text",
    label: "Título:",
  },
  {
    name: "Observations",
    type: "text",
    label: "Observaciones:",
  },
  {
    name: "technicianCharge",
    type: "autocomplete",
    label: "Técnico a cargo:",
    options: [
      {
        value: "Adrian",
        display: "Adrian",
      },
      {
        value: "Alberto Arreola",
        display: "Alberto Arreola",
      },
    ],
  },
  {
    name: "project",
    type: "autocomplete",
    label: "Proyecto:",
    options: [
      {
        value: "PRUEBA",
        display: "PRUEBA",
      },
      {
        value: "CLIMAS",
        display: "CLIMAS GAS COM ORIENTE SEGUNDO CICLO",
      },
    ],
  },
  {
    name: "ServiceClass",
    type: "autocomplete",
    label: "Clase de Servicio:",
    options: [
      {
        value: "Domicilio",
        display: "Domicilio",
      },
      {
        value: "Local",
        display: "Local",
      },
    ],
  },
];

export const Properties: IProperty[] = [
  { name: "ID", type: "text" },
  { name: "folio", type: "text" },
  { name: "dateString", type: "text" },
  { name: "statusLabel", type: "label" },
  { name: "Qualification", type: "text" },
  { name: "customer", type: "text" },
  { name: "devices", type: "text" },
  { name: "earrings", type: "text" },
  { name: "user", type: "text" },
];

export const Headers: string[] = [
  "ID",
  "Folio",
  "Fecha",
  "Estatus",
  "Título",
  "Cliente/Contacto",
  "Equipos",
  "Pendientes",
  "Usuario",
];
