import { KTSVG } from '../../../_metronic/helpers'

interface Props {
  title?: string
  message?: string
  children?: JSX.Element
  type: 'message' | 'alert'
}

const Messages = ({ title, message, children, type }: Props) => {
  return (
    <div className={`notice d-flex bg-light-${type === `message` ? `warning` : `danger`} rounded border-${type === `message` ? `warning` : `danger`} border border-dashed p-${type === `message` ? `6` : `3`} mb-5 w-100`}>

      <div className='d-flex flex-stack flex-grow-1'>
        <div className='fw-bold'>
          {message !== undefined && (
            <>
              {(title || '') !== '' && <h4 className='text-gray-800 fw-bolder'>{title}</h4>}

              <div className='fs-6 text-gray-600'>{message}</div>
            </>
          )}
          {children !== undefined && children}
        </div>
      </div>
    </div>
  )
}

export default Messages
