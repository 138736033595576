import { Button, Grid, Input } from "@mui/material";
import TextInput from "../TextInput/index.component";

interface Props {
  label: string;
  name: string;
  value: string;
  change: (value: any) => void;
  alertMessage?: string;
  disable?: boolean;
}

const InputFile = ({
  label,
  name,
  value,
  change,
  alertMessage,
  disable,
}: Props) => {
  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    // Aquí puedes hacer algo con el archivo seleccionado, como enviarlo a un servidor o procesarlo localmente.
  };
  return (
    <Grid container item direction={"row"} columns={12} gap={0.5}>
      <Grid item xs={12}>
        <TextInput
          label={`inputFile-${label}-0`}
          name={name}
          value={value}
          change={(value) => {}}
          alertMessage={""}
          disable={true}
        />
      </Grid>
      <Grid item xs={0}>
        <Input
          hidden
          id="upload-file"
          type="file"
          onChange={handleFileUpload}
        />
      </Grid>
      <Grid container item xs={12} justifyContent={"right"}>
        <label htmlFor="upload-file">
          <Button variant="contained" component="span">
            Subir archivo
          </Button>
        </label>
      </Grid>
    </Grid>
  );
};

export default InputFile;
