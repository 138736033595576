import { Button, Grid } from "@mui/material";
import DynamicInputs, {
  modeFormType,
} from "../../../Components/inputs/DynamicInputs/index.component";
import { IInputsForm } from "../interfaces/inputs.interface";
import { useEffect } from "react";
import INEComplements from "../Components/INE_Complement.component";

export const useBillPageForm = (
  modeForm: modeFormType,
  setData: React.Dispatch<React.SetStateAction<any>>,
  data: any,
  inputs: IInputsForm
) => {
  useEffect(() => {
    console.log("useBillPageForm=> data => ", data);
  }, [data]);

  const Form = (
    <Grid container rowGap={2.5}>
      <Grid container item justifyContent={"flex-start"}>
        <DynamicInputs
          inputs={inputs.Client}
          columns={1}
          data={data}
          setData={setData}
          mode={modeForm}
        />
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <DynamicInputs
          inputs={inputs.GeneralData}
          columns={4}
          data={data}
          setData={setData}
          mode={modeForm}
        />
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <DynamicInputs
          inputs={inputs.Section_3}
          columns={1}
          data={data}
          setData={setData}
          mode={modeForm}
        />
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <DynamicInputs
          inputs={inputs.Section_4}
          columns={2}
          data={data}
          setData={setData}
          mode={modeForm}
        />
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <DynamicInputs
          inputs={inputs.Section_5}
          columns={1}
          data={data}
          setData={setData}
          mode={modeForm}
        />
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <h4>Productos o Servicios</h4>
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        {/* TODO Agregar componente para carga de productos */}
        <span>componente para carga de productos</span>
      </Grid>
      <Grid container item justifyContent={"flex-end"} columns={12}>
        <Grid container item xs={3}>
          <DynamicInputs
            inputs={inputs.Section_6}
            columns={1}
            data={data}
            setData={setData}
            mode={modeForm}
            rowSpacing={0}
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <h4>Impuestos Locales</h4>
      </Grid>{" "}
      <Grid container item justifyContent={"flex-start"}>
        {/* TODO Agregar componente para carga de impuestos locales */}
        <span>componente para carga de impuestos locales</span>
      </Grid>
      <Grid container item justifyContent={"flex-end"} columns={12}>
        <Grid container item xs={3}>
          <DynamicInputs
            inputs={inputs.Section_7}
            columns={2}
            data={data}
            setData={setData}
            mode={modeForm}
            rowSpacing={0}
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <h4>Información Global</h4>
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <DynamicInputs
          inputs={inputs.Section_8}
          columns={3}
          data={data}
          setData={setData}
          mode={modeForm}
        />
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <h4>Firmas de acuerdo</h4>
      </Grid>
      <Grid container item justifyContent={"flex-start"} rowSpacing={1}>
        <DynamicInputs
          inputs={inputs.Section_9}
          columns={3}
          data={data}
          setData={setData}
          mode={modeForm}
        />
        <DynamicInputs
          inputs={inputs.Section_10}
          columns={2}
          data={data}
          setData={setData}
          mode={modeForm}
        />
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <h4>Relacionar con factura</h4>
      </Grid>
      <Grid
        container
        item
        justifyContent={"flex-start"}
        rowSpacing={1}
        columns={12}
      >
        <Grid
          container
          item
          justifyContent={"flex-start"}
          rowSpacing={1}
          xs={6}
        >
          <DynamicInputs
            inputs={inputs.Section_11}
            columns={2}
            data={data}
            setData={setData}
            mode={modeForm}
          />
          <DynamicInputs
            inputs={inputs.Section_12}
            columns={1}
            data={data}
            setData={setData}
            mode={modeForm}
          />
        </Grid>
        <Grid
          container
          item
          justifyContent={"flex-start"}
          rowSpacing={1}
          xs={6}
        >
          <Button
            variant="contained"
            onClick={(e) => {}}
            style={{ maxHeight: "40px", marginTop: "8px", marginLeft: "8px" }}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <h4>Facturas Relacionadas</h4>
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        {/* TODO Agregar componente para Facturas Relacionadas */}
        <span>componente para Facturas Relacionadas</span>
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <h4>Complemento INE</h4>
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <INEComplements data={data} setData={setData} />
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <h4>Observaciones</h4>
      </Grid>
      <Grid container item justifyContent={"flex-start"} rowSpacing={1}>
        <DynamicInputs
          inputs={inputs.Section_13}
          columns={1}
          data={data}
          setData={setData}
          mode={modeForm}
        />
      </Grid>
      <Grid container item justifyContent={"flex-start"}>
        <h4>Complemento Detallista Liverpool</h4>
      </Grid>
      <Grid container item justifyContent={"flex-start"} rowSpacing={1}>
        <DynamicInputs
          inputs={inputs.Section_14}
          columns={2}
          data={data}
          setData={setData}
          mode={modeForm}
        />
      </Grid>
    </Grid>
  );

  return { Form };
};
