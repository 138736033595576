import { useCallback, useEffect, useState } from "react";
import {
  INTERNAL_MESSAGES,
  useApiRequest,
} from "../../../Hooks/ApiRequest/ApiRequest.component";
import { IServiceOrder } from "../../../interfaces/serviceOrder.interface";
import { getFormattedDate } from "../../../Utils/dateHelper";

export const useServiceOrder = <Request, response>() => {
  const [response, setResponse] = useState<any[]>([]);
  const [msgError, setMsgError] = useState<string>("");

  const Client = useApiRequest<
    {},
    { rowCount: number; page: number; data: IServiceOrder[] }
  >({ path: "service-order", method: "GET" });

  const Get = useCallback(() => {
    Client.execute();
  }, []);

  useEffect(() => {
    if (Client.status === "success" || Client.status === "error") {
      setMsgError(
        Client.status === "success"
          ? ""
          : Client.value?.message || INTERNAL_MESSAGES.ERROR_MESSAGE
      );

      if (Client.status === "success") {
        setResponse(ConvertData(Client.value?.data?.data as IServiceOrder[]));
      }
    }
  }, [Client.status]);

  function ConvertData(data: IServiceOrder[]): any {
    const result: any[] = [];

    data.map((n) => {
      result.push({
        ...n,
        statusLabel: [
          {
            label: `${n.status} - ${n.porcent}%`,
            bgcolor: "#EAEAEA",
            color: "black",
          },
        ],
        dateString: getFormattedDate(n.date || new Date()),
      });
    });

    return result;
  }

  return { Get, response, msgError };
};
