import { InputInterface } from "../../../Components/inputs/DynamicInputs/index.component";

export const dataMock: InputInterface[] = [
  {
    name: "certificate",
    type: "inputFile",
    label: "Certificado (.cer):",
  },
  {
    name: "certificateKey",
    type: "inputFile",
    label: "Llave (.key):",
  },
  {
    name: "password",
    type: "text",
    label: "Clave Privada:",
  },
];
