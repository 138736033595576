import { createSlice } from "@reduxjs/toolkit";

export interface IUser {
  id: string;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  token: string;
  isLogged: boolean;
  rfc: string;
  idCpa: string;
  tipo: "NEW" | "CRM" | "TRM" | "";
}

export const defaultUser: IUser = {
  id: "",
  nombre: "",
  apellidoPaterno: "",
  apellidoMaterno: "",
  token: "",
  isLogged: false,
  rfc: "",
  idCpa: "",
  tipo: "NEW",
};

export const userSlice = createSlice({
  name: "user",
  initialState: defaultUser,
  reducers: {
    setUser: (state: any, action: any) => ({ ...state, ...action.payload }),
    editUser: (state: any, action: any) => ({ ...state, ...action.payload }),
    resetUser: () => defaultUser,
  },
});

export const { setUser, editUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
