import { Grid } from "@mui/material";

interface Props {
  labels: ITableInput_Label[];
}

export interface ITableInput_Label {
  label: string;
  bgcolor?: string;
  color?: string;
}

const TableInput_Label = ({ labels }: Props) => {
  return (
    <Grid container item columns={12} gap={0.4}>
      {labels.map((_label: ITableInput_Label) => (
        <Grid
          item
          style={{
            background: _label.bgcolor || "#5867DD",
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 3,
            paddingBottom: 3,
            color: _label.color || "white",
            borderRadius: 11,
          }}
        >
          <span>{_label.label}</span>
        </Grid>
      ))}
    </Grid>
  );
};

export default TableInput_Label;
