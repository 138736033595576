import { Button, Divider, Grid } from "@mui/material";
import { PageTitle } from "../../../../_metronic/layout/core";
import DynamicInputs from "../../../Components/inputs/DynamicInputs/index.component";
import { inputsCreateJSON, inputsCreate_PermissionsJSON } from "../utils";
import { DefaultValueUser, IUser } from "../../../interfaces/user.interface";
import { useEffect, useState } from "react";
import { useSucursales } from "../hooks/useSucursales";
import { IOption } from "../../../Components/inputs/SelectInput/index.component";
import { useTypes } from "../hooks/useTypes";
import { usePermissions } from "../hooks/usePermissions";

export interface Props {
  labelConfirmButton: string;
  labelCancelButton: string;
  actionConfirmButton: (data: IUser) => void;
  actionCancelButton: () => void;
  getDataInfo: IUser | undefined;
}

const User_Create = ({
  labelConfirmButton,
  labelCancelButton,
  actionConfirmButton,
  actionCancelButton,
  getDataInfo,
}: Props) => {
  const [data, setData] = useState<IUser>(DefaultValueUser);
  const [sucursales, setSucursales] = useState<string[]>([]);
  const useSucursalesHook = useSucursales();
  const useTypesHook = useTypes();
  const usePermissionsHook = usePermissions();

  const [inputs1, setInputs1] = useState(inputsCreateJSON);
  const [inputs2, setInputs2] = useState(inputsCreate_PermissionsJSON);

  useEffect(() => {
    if (
      useTypesHook.response === undefined ||
      useTypesHook.response.length === 0
    ) {
      return;
    }

    let _inputsTmp = inputs1;
    _inputsTmp.forEach((x) => {
      if (x.name === "TypeUser") {
        x.options = useTypesHook.response;
      }

      setInputs1(_inputsTmp);
    });
  }, [useTypesHook.response]);
  useEffect(() => {
    if (
      usePermissionsHook.response === undefined ||
      usePermissionsHook.response.length === 0
    ) {
      return;
    }

    let _inputsTmp = inputs2;
    _inputsTmp.forEach((x) => {
      if (x.name === "Modules") {
        x.options = usePermissionsHook.response;
      }

      setInputs2(_inputsTmp);
    });
  }, [usePermissionsHook.response]);
  useEffect(() => {
    useSucursalesHook.execute();
    useTypesHook.execute();
    usePermissionsHook.execute();
  }, []);
  useEffect(() => {
    if (useSucursalesHook.response === undefined) {
      return;
    }

    if (useSucursalesHook.msgError !== "") {
      return;
    }

    const _options: IOption[] = [];
    useSucursalesHook.response.forEach((x: any) => {
      _options.push({ display: x, value: x });
    });

    let _data = inputsCreateJSON;
    _data.forEach((x: any) => {
      if (x.name === "Sucursal") {
        x.options = _options;
      }

      setInputs1(_data);
    });
  }, [useSucursalesHook.response]);
  useEffect(() => {
    if (getDataInfo === undefined) {
      return;
    }
    setData(getDataInfo || DefaultValueUser);
  }, [getDataInfo]);

  return (
    <>
      <Grid className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <Grid container item className="mt-5" justifyContent={"center"}>
          <Grid container direction={"column"} item xs={10} rowSpacing={1}>
            <DynamicInputs
              inputs={inputs1}
              columns={2}
              data={data}
              setData={setData}
            />
            <DynamicInputs
              inputs={inputs2}
              columns={1}
              data={data}
              setData={setData}
            />

            <Divider
              sx={{ bgcolor: "black", marginTop: 3, marginBottom: 3 }}
              flexItem
            />

            <Grid container columnGap={1} justifyContent={"end"} item>
              <Grid item>
                <Button variant="outlined" onClick={actionCancelButton}>
                  {labelCancelButton}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    actionConfirmButton(data);
                  }}
                >
                  {labelConfirmButton}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default User_Create;
