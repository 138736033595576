import { useEffect, useState } from "react";
import {
  INTERNAL_MESSAGES,
  useApiRequest,
} from "../ApiRequest/ApiRequest.component";
import { IOption } from "../../Components/inputs/SelectInput/index.component";
import { FederalEntityAdapter } from "./FederalEntity.adapter";

export interface IFederalEntity {
  ID: string;
  name: string;
}

export const useFederalEntity = () => {
  const [response, setResponse] = useState<IOption[]>([]);
  const [msgError, setMsgError] = useState("");

  const Client = useApiRequest<
    {},
    { rowCount: number; page: number; data: IFederalEntity[] }
  >({
    path: "federal-entity",
    method: "GET",
  });

  const execute = () => Client.execute();
  const isSuccess = () => {
    setMsgError("");
    setResponse(
      FederalEntityAdapter(Client.value?.data?.data as IFederalEntity[]) || []
    );
  };
  const hasError = () => {
    setMsgError(Client.value?.message || INTERNAL_MESSAGES.ERROR_MESSAGE);
    setResponse([]);
  };

  useEffect(() => {
    if (Client.status === "success") isSuccess();
    if (Client.status === "error") hasError();
  }, [Client.status]);

  return { execute, msgError, response };
};
