import { useEffect, useState } from "react";
import { InputInterface } from "../../../Components/inputs/DynamicInputs/index.component";
import { useFederalEntity } from "../../../Hooks/FederalEntity/useFederalEntity";

export const useINEComplements = (setData: React.Dispatch<any>, data: any) => {
  const inputSelectDefault: InputInterface[] = [
    {
      name: "INEComplements_processType",
      type: "select",
      label: "Tipo de Proceso:",
      options: [
        {
          value: "1",
          display: "ORDINARIO  COMMITE EJECUTIVO ESTATAL",
        },
        {
          value: "2",
          display: "ORDINARIO  COMMITE EJECUTIVO NACIONAL",
        },
        {
          value: "3",
          display: "PRECAMPAÑA LOCAL",
        },
        {
          value: "4",
          display: "PRECAMPAÑA FEDERAL",
        },
        {
          value: "5",
          display: "CAMPAÑA LOCAL",
        },
        {
          value: "6",
          display: "CAMPAÑA FEDERAL",
        },
      ],
    },
  ];
  const useFederalEntityHook = useFederalEntity();
  const [columns, setColumns] = useState(4);
  const [ProcessType, setProcessType] = useState<string>("");
  const [dataComponent, setDataCompoenent] = useState<any>(undefined);
  const [inputSelect, setInputSelect] = useState(inputSelectDefault);

  const inputComiteEjecutivoNacional: InputInterface[] = [
    {
      name: "INEComplements_IdContabilidad",
      type: "text",
      label: "Id Contabilidad:",
    },
  ];
  const inputAddEntidadFederativa: InputInterface[] = [
    {
      name: "INEComplements_Entidad",
      type: "select",
      label: "Entidad Federativa:",
      options: useFederalEntityHook.response,
    },
    {
      name: "INEComplements_IdContabilidad",
      type: "text",
      label: "Id Contabilidad:",
    },
    {
      name: "INEComplements_AddEntidad",
      type: "buttonCustom",
      label: "Agregar Entidad",
      btnAction: () => {},
    },
  ];

  useEffect(() => {
    switch (ProcessType) {
      case "2":
        setColumns(
          inputSelectDefault.concat(inputComiteEjecutivoNacional).length
        );
        setInputSelect(inputSelectDefault.concat(inputComiteEjecutivoNacional));
        break;
      case "":
        setColumns(4);
        setInputSelect(inputSelectDefault);
        break;
      default:
        setColumns(inputSelectDefault.concat(inputAddEntidadFederativa).length);
        setInputSelect(inputSelectDefault.concat(inputAddEntidadFederativa));
    }
  }, [ProcessType]);
  useEffect(() => {
    if (dataComponent === undefined) return;

    setProcessType(
      !dataComponent.hasOwnProperty("INEComplements_processType")
        ? ""
        : dataComponent.INEComplements_processType
    );
  }, [dataComponent]);
  useEffect(() => {
    useFederalEntityHook.execute();
  }, []);
  // useEffect(() => {
  //   useFederalEntityHook.execute();
  // }, [useFederalEntityHook.response]);
  return {
    setProcessType,
    inputSelect,
    dataComponent,
    setDataCompoenent,
    columns,
  };
};
