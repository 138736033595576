import { IBranchOffice } from "../interfaces/BranchOffice.interface";

export const BranchOfficeAdapter = (data: IBranchOffice[]): any => {
  const result: any[] = [];

  data.map((row: IBranchOffice) => {
    const _Locations: any[] = [];

    row.location.map((item) => {
      _Locations.push({
        label: item,
      });

      result.push({
        ...row,
        location: _Locations,
      });
    });
  });
  return result;
};
