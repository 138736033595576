import { IProperty } from "../../../Components/inputs/TableInput/index.component";
import { convertData_Adapter } from "../adapters/convertData.adapter";

export const dataMock = convertData_Adapter([
  {
    ID: "1",
    title: "Razón Social",
    value: "VICTOR ALBERTO CRUZ PEREZ",
  },
  {
    ID: "2",
    title: "R. F. C.",
    value: "CUPV8410106U1",
  },
  {
    ID: "3",
    title: "CURP.",
    value: "CUPV841010HCSRRC00",
  },
  {
    ID: "4",
    title: "Regimen Fiscal.",
    value: "Personas Físicas con Actividades Empresariales y Profesionales",
  },
]);

export const Headers: string[] = ["ID", "", ""];

export const Properties: IProperty[] = [
  { name: "ID", type: "text" },
  { name: "title", type: "link" },
  { name: "value", type: "text" },
];
