import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";
import { IOption } from "../SelectInput/index.component";

// export interface IOption {
//   label: string;
//   id: string;
// }

interface Props {
  label: string;
  name: string;
  value: string;
  change: (value: any) => void;
  options: string[];
  style?: any;
  disable?: boolean;
}

const AutocompleteInput = ({
  label,
  change,
  name,
  value,
  style,
  options,
  disable,
}: Props) => {
  return (
    <>
      <Autocomplete
        key={name}
        disablePortal
        value={value}
        onChange={(event: any, newValue: string | null) => change(newValue)}
        id={name}
        options={options}
        sx={{ ...style, width: "100%" }}
        renderInput={(params) => <TextField {...params} label={label} />}
        disabled={disable}
      />
    </>
  );
};

export default AutocompleteInput;
