import { Button, Grid } from "@mui/material";

export type modeFormType = "consult" | "edition";

interface Props {
  Title: string;
  Subtitle?: string;
  mode: modeFormType;
  handleNew?: any;
  handleRefresh?: any;
  handleSave?: any;
  handleCancel?: any;
  hideNewButton?: boolean;
  hideRefreshButton?: boolean;
  hideSaveButton?: boolean;
  hideCancelButton?: boolean;
}

const FormTitle = ({
  Title,
  Subtitle,
  mode,
  handleNew,
  handleRefresh,
  handleSave,
  handleCancel,
  hideNewButton,
  hideRefreshButton,
  hideSaveButton,
  hideCancelButton,
}: Props) => {
  return (
    <Grid container item direction={"row"} columns={12}>
      <Grid container item xs={6} direction={"column"}>
        <Grid item>
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">{Title}</span>
          </h3>
        </Grid>
        {Subtitle && (
          <Grid item>
            <h3 className="card-title align-items-start flex-column">
              <span className="text-muted mt-1 fw-semibold fs-7">
                {Subtitle}
              </span>
            </h3>
          </Grid>
        )}
      </Grid>
      <Grid container item direction={"column"} xs={6} columns={12}>
        <Grid
          container
          item
          justifyContent={"right"}
          gap={1}
          xs={12}
          style={{ width: "100%" }}
        >
          {mode === "consult" && (
            <>
              {hideNewButton !== true && (
                <Grid item>
                  <Button variant="contained" onClick={(e) => handleNew(e)}>
                    Nuevo
                  </Button>
                </Grid>
              )}
              {hideRefreshButton !== true && (
                <Grid item>
                  <Button variant="contained" onClick={(e) => handleRefresh(e)}>
                    Actualizar
                  </Button>
                </Grid>
              )}
            </>
          )}
          {mode === "edition" && (
            <>
              {hideSaveButton !== true && (
                <Grid item>
                  <Button variant="contained" onClick={(e) => handleSave(e)}>
                    Guardar
                  </Button>
                </Grid>
              )}
              {hideCancelButton !== true && (
                <Grid item>
                  <Button variant="contained" onClick={(e) => handleCancel(e)}>
                    Cancelar
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormTitle;
