import { useCallback, useEffect, useState } from "react";
import { IUser } from "../../../interfaces/user.interface";
import {
  INTERNAL_MESSAGES,
  useApiRequest,
} from "../../../Hooks/ApiRequest/ApiRequest.component";
import { getFormattedDate } from "../../../Utils/dateHelper";

export const useUser = <request, response>() => {
  const [response, setResponse] = useState<any[]>([]);
  const [msgError, setMsgError] = useState("");

  const Client = useApiRequest<
    {},
    { rowCount: number; page: number; data: IUser[] }
  >({ path: "user", method: "GET" });

  const GetAll = useCallback(() => {
    Client.execute();
  }, []);

  useEffect(() => {
    if (Client.status === "success") {
      setMsgError("");
      setResponse(Client.value?.data?.data as IUser[]);
    }
    if (Client.status === "error") {
      setMsgError(Client.value?.message || INTERNAL_MESSAGES.ERROR_MESSAGE);
    }
  }, [Client.status]);

  function ConvertData(data: IUser[]): any {
    const result: any[] = [];

    data.map((_user: IUser) => {
      const _Modules: any[] = [];
      const _Stamps: any[] = [];
      const _Validity: any[] = [];

      _user.Modules.map((item) => {
        _Modules.push({
          label: item,
          bgcolor:
            item === "MultiEmpresa"
              ? "#EAEAEA"
              : item === "MonoEmpresa"
              ? "#34BFA3"
              : undefined,
          color: item === "MultiEmpresa" ? "black" : undefined,
        });
      });
      _Stamps.push({
        label: _user.Stamps,
        bgcolor: _user.Stamps === 0 ? "#F4516C" : "#34BFA3",
      });
      _Validity.push({
        label: _user.Validity,
        bgcolor:
          _user.Validity === "Vigente"
            ? "#34BFA3"
            : _user.Validity === "Suspendido"
            ? "#F4516C"
            : undefined,
      });

      const newElement = {
        ID: _user.ID,
        User: _user.User,
        Name: _user.Name,
        SystemId: _user.SystemId,
        BD: { display: _user.BD, url: "#" },
        Modules: _Modules,
        Stamps: _Stamps,
        UserStatus: _user.UserStatus,
        Validity: _Validity,
        LastPurchase:
          _user.LastPurchase === undefined
            ? undefined
            : [
                {
                  label: getFormattedDate(_user.LastPurchase || new Date()),
                  bgcolor: _user.UserStatus !== "Vigente" ? "#EAEAEA" : "white",
                  color: "black",
                },
              ],
        Days: _user.Days,
      };

      result.push(newElement);
    });

    return result;
  }

  return { response, GetAll, ConvertData, msgError };
};
