import { Button, Grid } from "@mui/material";
import TextInput from "../TextInput/index.component";
import SelectInput, {
  IOption as OptionSelect,
} from "../SelectInput/index.component";
import { useEffect } from "react";
import AutocompleteInput from "../Autocomplete";
import MultiSelectInput from "../MultiSelect";
import CheckboxInput from "../Checkbox/index.component";
import InputFile from "../InputFile";

export type modeFormType = "consult" | "edition";

export interface InputInterface {
  name: string;
  type:
    | "text"
    | "select"
    | "autocomplete"
    | "password"
    | "multiselect"
    | "check"
    | "inputFile"
    | "buttonCustom"
    | "simpleLabel";
  disable?: boolean;
  label: string;
  required?: boolean;
  helperText?: string;
  options?: OptionSelect[];
  btnAction?: () => void;
}

interface Props {
  inputs: InputInterface[];
  columns: number;
  data: any;
  setData: (value: any) => void;
  mode?: modeFormType;
  rowSpacing?: number;
  columnSpacing?: number;
}
const DynamicInputs = ({
  inputs,
  columns,
  data,
  setData,
  mode,
  rowSpacing = 1,
  columnSpacing = 1,
}: Props) => {
  function getColumnSize(): number {
    return Math.trunc(12 / columns);
  }

  const setValue = (newData: any) => {
    const { value, name } = newData.target;

    setData({ ...data, [name]: value });
  };

  const getValue = (propertyName: string) => {
    let value: string = "";
    if (data === undefined) return "";
    value = !data.hasOwnProperty(propertyName) ? "" : data[propertyName] || "";
    return value;
  };

  const getValueAutocomplete = (propertyName: string) => {
    let value: string = "";

    if (data === undefined) return "";
    value = !data.hasOwnProperty(propertyName) ? "" : data[propertyName] || "";
    return value;
  };
  const setValueAutocomplete = (name: string, newData: any) => {
    const { value } = newData.target;
    setData({ ...data, [name]: value });
  };
  const getValueMultiSelect = (name: string, value: string[]) => {
    setData({ ...data, [name]: value });
  };
  function getOptionSelect(data: OptionSelect[]): OptionSelect[] {
    const result: OptionSelect[] = [];

    data.map((x) => {
      result.push({ display: x.display, value: x.value });
    });

    return result;
  }
  function getOptionAutoComplete(data: OptionSelect[]): string[] {
    const result: string[] = [];

    data.map((x) => {
      result.push(x.display);
    });

    return result;
  }

  return (
    <>
      <Grid
        container
        item
        columns={12}
        columnSpacing={columnSpacing}
        rowSpacing={rowSpacing}
        direction={"row"}
        justifyContent={"space-between"}
        // style={{ border: "1px solid red" }}
      >
        {inputs.map((_input: InputInterface, index: number) => (
          <Grid
            container
            item
            xs={getColumnSize()}
            key={`${_input.type}_${index}`}
          >
            {_input.type === "buttonCustom" && (
              <Button
                variant="contained"
                onClick={(e) => {}}
                key={`${_input.name}_${index}`}
              >
                {_input.label}
              </Button>
            )}
            {_input.type === "text" && (
              <TextInput
                key={`${_input.name}_${index}`}
                label={_input.label}
                name={_input.name}
                value={getValue(_input.name)}
                change={(value) => setValue(value)}
                alertMessage={""}
                disable={mode === "consult" ? true : undefined}
              />
            )}
            {_input.type === "password" && (
              <TextInput
                key={`${_input.name}_${index}`}
                label={_input.label}
                name={_input.name}
                value={getValue(_input.name)}
                change={(value) => setValue(value)}
                alertMessage={""}
                password={true}
                disable={mode === "consult" ? true : undefined}
              />
            )}
            {_input.type === "select" && (
              <SelectInput
                _key={`${_input.name}_${index}`}
                label={_input.label}
                name={_input.name}
                value={getValueAutocomplete(_input.name)}
                change={(value) => setValueAutocomplete(_input.name, value)}
                alertMessage={""}
                options={getOptionSelect(_input.options || [])}
                disable={mode === "consult" ? true : undefined}
              />
            )}
            {_input.type === "autocomplete" && (
              <AutocompleteInput
                key={`${_input.name}_${index}`}
                label={_input.label}
                name={_input.name}
                options={getOptionAutoComplete(_input.options || [])}
                value={data[_input.name] || ""}
                change={(value) => setValueAutocomplete(_input.name, value)}
                disable={mode === "consult" ? true : undefined}
              />
            )}
            {_input.type === "multiselect" && (
              <MultiSelectInput
                key={`${_input.name}_${index}`}
                label={_input.label}
                name={_input.name}
                options={_input.options || []}
                // options={getOptionAutoComplete(_input.options || [])}
                value={data[_input.name] || []}
                change={(value) => getValueMultiSelect(_input.name, value)}
                disable={mode === "consult" ? true : undefined}
              />
            )}
            {_input.type === "check" && (
              <CheckboxInput
                key={`${_input.name}_${index}`}
                label={_input.label}
                name={_input.name}
                value={data[_input.name] || false}
                change={(value) => setValue(value)}
                disabled={mode === "consult" ? true : undefined}
              />
            )}
            {_input.type === "inputFile" && (
              <InputFile
                key={`${_input.name}_${index}`}
                label={_input.label}
                name={_input.name}
                value={data[_input.name] || undefined}
                change={(value) => {}}
              />
            )}
            {_input.type === "simpleLabel" && (
              <Grid item key={`${_input.name}_${index}`}>
                {_input.label}
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DynamicInputs;
