import { Grid } from "@mui/material";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../_metronic/helpers";

interface Props {
  Title?: string;
  children: JSX.Element;
  show: boolean;
  handleClose: () => void;
  width?: number;
}

const CustomModal = ({ Title, children, show, handleClose, width }: Props) => {
  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName={`modal-dialog modal-dialog-centered mw-${
        width ? width : 900
      }px`}
      backdrop={true}
      show={show}
      onHide={handleClose}
    >
      <Grid container justifyContent={"space-between"} className={"p-4"}>
        <Grid item>
          <h3>{Title || "Atención"}</h3>
        </Grid>
        <Grid item onClick={handleClose}>
          <KTSVG
            className="svg-icon-1"
            path="/media/icons/duotune/arrows/arr061.svg"
          />
        </Grid>
      </Grid>
      <Grid container className={"p-4"} style={{ width: "100%" }}>
        {children}
      </Grid>
    </Modal>
  );
};

export default CustomModal;
