import { useEffect, useState } from "react";
import { ITabInput_Item } from "../../interfaces/TabInput/useTabInput.interface";

export const useTabInput = (newTabs: ITabInput_Item[]) => {
  const [tab, setTab] = useState<string>("");
  const [tabs, setTabs] = useState<ITabInput_Item[]>(newTabs);
  const [body, setBody] = useState<JSX.Element>(<span></span>);

  useEffect(() => {
    if (tab === "") {
      setBody(<span></span>);
      return;
    }

    const _element: ITabInput_Item[] = tabs.filter((x) => x.tabName === tab);

    if (_element === undefined) {
      setBody(<span></span>);
      return;
    }

    setBody(_element[0].body || <span></span>);
  }, [tab]);

  useEffect(() => {
    const filter = tabs.filter((_value, _index, _array) => _array[0]);

    if (filter !== undefined) {
      setTab(filter[0].tabName || "");
    }
  }, []);

  return {
    tab,
    body,
    tabs,
    setTab,
    setTabs,
  };
};
