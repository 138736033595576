/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from "../../../_metronic/partials/widgets";
import Messages from "../../Components/Messages/index.component";
import CustomModal from "../../Components/Modal/index.componente";
import ServiceOrderForm, {
  PropsCardServiceOrder,
} from "../../Components/ServiceOrder/directAccess";
import FormServiceOrder from "../../Components/ServiceOrder/Form";
import { Button, Grid } from "@mui/material";

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
      {/* begin::Col */}
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
        <CardsWidget20
          className="h-md-50 mb-5 mb-xl-10"
          description="Active Projects"
          color="#F1416C"
          img={toAbsoluteUrl("/media/patterns/vector-1.png")}
        />
        <CardsWidget7
          className="h-md-50 mb-5 mb-xl-10"
          description="Professionals"
          icon={false}
          stats={357}
          labelColor="dark"
          textColor="gray-300"
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
        <CardsWidget17 className="h-md-50 mb-5 mb-xl-10" />

        <ListsWidget26 className="h-lg-50" />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className="col-xxl-6">
        <EngageWidget10 className="h-md-100" />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row gx-5 gx-xl-10">
      {/* begin::Col */}
      <div className="col-xxl-6 mb-5 mb-xl-10">
        {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className="col-xxl-6 mb-5 mb-xl-10">
        {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row gy-5 gx-xl-8">
      <div className="col-xxl-4">
        <ListsWidget3 className="card-xxl-stretch mb-xl-3" />
      </div>
      <div className="col-xl-8">
        <TablesWidget10 className="card-xxl-stretch mb-5 mb-xl-8" />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row gy-5 g-xl-8">
      <div className="col-xl-4">
        <ListsWidget2 className="card-xl-stretch mb-xl-8" />
      </div>
      <div className="col-xl-4">
        <ListsWidget6 className="card-xl-stretch mb-xl-8" />
      </div>
      <div className="col-xl-4">
        <ListsWidget4 className="card-xl-stretch mb-5 mb-xl-8" items={5} />
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      </div>
    </div>
    {/* end::Row */}

    <div className="row g-5 gx-xxl-8">
      <div className="col-xxl-4">
        <MixedWidget8
          className="card-xxl-stretch mb-xl-3"
          chartColor="success"
          chartHeight="150px"
        />
      </div>
      <div className="col-xxl-8">
        <TablesWidget5 className="card-xxl-stretch mb-5 mb-xxl-8" />
      </div>
    </div>
  </>
);

interface Props {
  messages?: string[];
  orders?: PropsCardServiceOrder[];
  onClick?: any;
}

const DashboardPage2 = ({ messages, orders, onClick }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{"Mensajes"}</PageTitle> */}
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-body p-9">
          {messages?.map((x) => (
            <Messages title="Estimado usuario" message={x} type="message" />
          ))}
        </div>
      </div>
      <PageTitle breadcrumbs={[]}>{"Acceso Rápido"}</PageTitle>
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        {/* <div className="card-body p-9" style={{ border: "1px solid red" }}>  serviceOrder*/}
        <Grid container item columns={15} gap={2} className="card-body p-9">
          <Grid container item justifyContent={"right"} gap={1}>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => navigate("/serviceOrder")}
              >
                Ordenes de Servicio
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => navigate("/serviceOrder/Create/")}
              >
                Agregar Nueva Orden
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => navigate("/Users")}>
                Usuarios
              </Button>
            </Grid>
          </Grid>

          <Grid container item justifyContent={"center"}>
            <ServiceOrderForm orders={orders} onClick={onClick} />
          </Grid>
        </Grid>
        {/* </div> */}
      </div>
    </>
  );
};

const DashboardWrapper: FC = () => {
  const [valueSelected, setValueSelected] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [messages, setMessages] = useState<string[]>([
    "Le informamos que ya puede contratar el módulo de Carta Porte. Para más información llamé o envíe un mensaje al teléfono: 9611557648.",
    'Le informamos que ¡Ya estamos listos para timbrar con CFDI 4.0! Ya puedes utilizar nuestro servicio de timbrado de CFDI versión 4.0. Podrá acceder desde el menú lateral dando click en el Menú "Factura 4.0". Para cualquier duda o aclaración llame o envíe un mensaje al teléfono: 9611557648.',
  ]);
  const [titleModal, settitleModal] = useState<string>("");

  const [orders, setOrders] = useState<PropsCardServiceOrder[]>([
    {
      id: "SERV46",
      description: "ASISTENCIA TECNICA",
      codigo: "ATC001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV51",
      description: "INSTALACIÓN BÁSICA DE MINISPLIT DE 1 A 3 TONELADAS",
      codigo: "CLI-IBM",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV56",
      description: "INSTALACIÓN DE CÁMARA",
      codigo: "CAMINS001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV58",
      description: "DESMONTAJE DE CLIMA MINISPLIT NORMAL/INVERTER",
      codigo: "CLI-DEM",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV60",
      description:
        "MANTENIMIENTO PREVENTIVO COMPLETO CLIMA MINISPLIT DE 1 A 1.5 TONELADAS",
      codigo: "CLIMPC001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV62",
      description: "ENSAMBLE DE EQUIPO DE COMPUTO NUEVO",
      codigo: "COMEEN001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV63",
      description: "INSTALACIÓN DE NODOS DE RED",
      codigo: "REDINR001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV64",
      description: "ASISTENCIA TÉCNICA COMPUTACIONAL",
      codigo: "ATCCOM001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV66",
      description: "INSTALACIÓN DE CONCENTRADO ( DVR Y EQUIPOS)",
      codigo: "CAMINS002",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV68",
      description: "MANTENIMIENTO PREVENTIVO A CLIMA TIPO VENTANA",
      codigo: "CLIMPV001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV78",
      description:
        "MANTENIMIENTO PREVENTIVO BÁSICO DE AIRE ACONDICIONADO TIPO MINISPLIT DE 1 A 3 TONELADAS",
      codigo: "CLIMPB001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV85",
      description: "Ejecución de Mantenimiento a la Torre",
      codigo: "MANEJT001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV87",
      description: "MINISPLIT ENCIENDE PERO NO ENFRÍA",
      codigo: "CLIRGM001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV92",
      description:
        "MANTENIMIENTO PREVENTIVO BASICO DE CLIMA TIPO FAN AND COIL GAS ",
      codigo: "CLIMPBFC001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV96",
      description: "RECONFIGURACION A LAPTOP CON RESPALDO",
      codigo: "CFGLAP001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV97",
      description: "MANTENIMIENTO PREVENTIVO  DE CAMARAS",
      codigo: "CAMMPV002",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV98",
      description: "MANTENIMIENTO CORRECTIVO DE CAMARAS",
      codigo: "CAMMCV003",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV99",
      description: "MANTENIMIENTO CORRECTIVO DVR O NVR",
      codigo: "CAMMCV004",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV100",
      description: "CONFIGURACION DE CAMARA PTZ ",
      codigo: "CAMCFG001",
      image: "/media/icons/duotune/general/gen024.svg",
    },

    {
      id: "SERV101",
      description: "CONFIGURACION DE CAMARAS IP",
      codigo: "CAMCFG002",
      image: "/media/icons/duotune/general/gen024.svg",
    },
  ]);
  const intl = useIntl();

  const handlClick = (id: any) => {
    setValueSelected(id);
    setShowModal(true);
    settitleModal(
      `¿Seguro desea Crear la orden rápida ${
        orders.filter((x) => x.id === id)[0].description
      }?`
    );
  };

  const handleCrear = () => {
    setShowModal(false);
  };
  const handleCancelar = () => {
    setShowModal(false);
  };

  return (
    <>
      <DashboardPage2
        messages={messages}
        orders={orders}
        onClick={(e: any) => {
          handlClick(e);
        }}
      />
      <CustomModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        width={500}
      >
        <FormServiceOrder
          Title={titleModal}
          handleCrear={handleCrear}
          handleCancelar={handleCancelar}
        />
      </CustomModal>
    </>
  );
};

export { DashboardWrapper };
