import { useState } from "react";
import {
  _Headers,
  _Properties,
  dataMock,
} from "../utils/TransmitterFolios.mock";
import { IProperty } from "../../../Components/inputs/TableInput/index.component";
import { TransmitterFolioAdapter } from "../adapters/transmitterFolio.adapter";

export const useTransmitterFolios = () => {
  const title: string = "Folios";
  const [headers, setHeaders] = useState<string[]>(_Headers);
  const [properties, setProperties] = useState<IProperty[]>(_Properties);
  const [data, setData] = useState<any[]>(TransmitterFolioAdapter(dataMock));
  const handeNew = () => {};
  const handeEdit = (value: any) => {};
  const handeRefresh = () => {};

  return {
    title,
    headers,
    data,
    setData,
    handeNew,
    handeEdit,
    handeRefresh,
    properties,
  };
};
