import { useEffect, useState } from "react";
import TableInput, {
  IProperty,
} from "../../Components/inputs/TableInput/index.component";
import { IUser } from "../../interfaces/user.interface";
import { useUser } from "./hooks/useUser";
import CustomModal from "../../Components/Modal/index.componente";
import User_Create from "./components/create.component";

const Users_Page = () => {
  const Title: string = "Usuarios";
  const Headers: string[] = [
    "ID",
    "Nombre Usuario",
    "Nombre",
    "Id Sistema",
    "BD",
    "Modulos",
    "Timbres",
    "Estatus Usuario",
    "Vigencia",
    "Última compra",
    "Días",
  ];
  const Properties: IProperty[] = [
    { name: "ID", type: "text" },
    { name: "User", type: "text" },
    { name: "Name", type: "text" },
    { name: "SystemId", type: "text" },
    { name: "BD", type: "link" },
    { name: "Modules", type: "label" },
    { name: "Stamps", type: "label" },
    { name: "UserStatus", type: "text" },
    { name: "Validity", type: "label" },
    { name: "LastPurchase", type: "label" },
    { name: "Days", type: "text" },
  ];
  const userHook = useUser();
  const [data, setData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [tittleModal, setTittleModal] = useState("");
  const [userSelected, setUserSelected] = useState<IUser | undefined>(
    undefined
  );

  useEffect(() => {
    if (userHook.response === undefined || userHook.response.length === 0) {
      return;
    }
    setData(userHook.ConvertData(userHook.response));
  }, [userHook.response]);

  useEffect(() => {
    userHook.GetAll();
  }, []);

  const handleNew = () => {
    setUserSelected(undefined);
    setShowModal(true);
    setTittleModal("Nuevo Usuario");
  };
  const handleEdit = (e: any) => {
    const _userSelected = userHook.response.filter((x: any) => x.ID === e);

    setUserSelected(_userSelected[0] || undefined);
    setShowModal(true);
    setTittleModal("Editar Usuario");
  };

  const handleNewSave = (_user: any) => {
    console.log("Guardar nuevo");
    console.log(_user);
  };
  const handleUpdateSave = (_user: any) => {
    console.log("Actualizar");
    console.log(_user);
  };

  return (
    <>
      <TableInput
        Title={Title}
        headers={Headers}
        properties={Properties}
        data={data}
        handleNew={handleNew}
        handleEdit={(e: any) => handleEdit(e)}
        handleRefresh={() => userHook.GetAll()}
      />
      <CustomModal
        Title={tittleModal}
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        width={600}
      >
        <User_Create
          labelConfirmButton={
            tittleModal === "Nuevo Usuario"
              ? "Guardar Usuario"
              : "Actualizar Usuario"
          }
          labelCancelButton="Cerrar"
          actionCancelButton={() => {
            setShowModal(false);
          }}
          actionConfirmButton={(value: IUser) => {
            tittleModal === "Nuevo Usuario"
              ? handleNewSave(value)
              : handleUpdateSave(value);
            setShowModal(false);
          }}
          getDataInfo={userSelected}
        />
      </CustomModal>
    </>
  );
};

export default Users_Page;
