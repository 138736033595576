import { useCallback, useEffect, useState } from "react";
import {
  INTERNAL_MESSAGES,
  useApiRequest,
} from "../../../Hooks/ApiRequest/ApiRequest.component";

export const useSucursales = <request, response>() => {
  const [response, setResponse] = useState<any[]>([]);
  const [msgError, setMsgError] = useState("");

  const Client = useApiRequest<
    {},
    { rowCount: number; page: number; data: string[] }
  >({
    path: "user/GetSucursales",
    method: "GET",
  });

  const execute = useCallback(() => {
    Client.execute();
  }, []);

  useEffect(() => {
    if (Client.status === "success") {
      setMsgError("");
      setResponse((Client.value?.data?.data as any[]) || []);
    }
    if (Client.status === "error") {
      setMsgError(Client.value?.message || INTERNAL_MESSAGES.ERROR_MESSAGE);
      setResponse([]);
    }
  }, [Client.status]);

  return { execute, msgError, response };
};
