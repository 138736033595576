import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import TextInput from "../../inputs/TextInput/index.component";
import Messages from "../../Messages/index.component";
import SelectInput, { IOption } from "../../inputs/SelectInput/index.component";

interface IFormServiceOrderData {
  fullName: string;
}

interface Props {
  Title: string;
  handleCrear?: any;
  handleCancelar?: any;
}

const FormServiceOrder = ({ Title, handleCrear, handleCancelar }: Props) => {
  const [data, setData] = useState<IFormServiceOrderData>(
    {} as IFormServiceOrderData
  );
  const [options, setOptions] = useState<IOption[]>([
    {
      value: "1",
      display: "S4570 ORDEN DE SERVICIO -- TUXTLA GUTIERREZ, CHIAPAS",
    },
  ]);

  const change = (val: any) => {
    const { name, value } = val.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Grid item container w-100>
      <Grid item container w-100>
        <Grid item container w-100>
          <Messages title={"Crear Orden"} message={Title} type="message" />
        </Grid>
        <Grid item container w-100 gap={1.5}>
          <TextInput
            label="Título"
            name="titulo"
            change={change}
            value={data.fullName}
          />
          <SelectInput
            _key="Folio"
            label="Folio"
            name="folio"
            change={change}
            value={data.fullName}
            options={options}
          />
          <Grid
            item
            container
            gap={1.5}
            justifyContent={"right"}
            style={{ width: "100%" }}
          >
            <Grid item>
              <Button variant="outlined" onClick={handleCancelar}>
                Cancelar
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" onClick={handleCrear}>
                Crear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormServiceOrder;
