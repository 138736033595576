import { IInputsForm } from "../interfaces/inputs.interface";

export const inputsForm: IInputsForm = {
  Client: [
    {
      name: "client",
      type: "autocomplete",
      label: "Cliente:",
      options: [
        {
          value: "1",
          display: "Cliente 1",
        },
        {
          value: "2",
          display: "Cliente 2",
        },
        {
          value: "3",
          display: "Cliente 3",
        },
      ],
    },
  ],
  GeneralData: [
    {
      name: "bill",
      type: "autocomplete",
      label: "Factura:",
      options: [
        {
          value: "1",
          display: "Factura 1",
        },
        {
          value: "2",
          display: "Factura 2",
        },
        {
          value: "3",
          display: "Factura 3",
        },
      ],
    },
    {
      name: "currency",
      type: "autocomplete",
      label: "Moneda:",
      options: [
        {
          value: "1",
          display: "Moneda 1",
        },
        {
          value: "2",
          display: "Moneda 2",
        },
        {
          value: "3",
          display: "Moneda 3",
        },
      ],
    },
    {
      name: "CFDI",
      type: "autocomplete",
      label: "Uso de CFDI:",
      options: [
        {
          value: "1",
          display: "G01 - Adquisición de mercancías.",
        },
        {
          value: "2",
          display: "G02 - Devoluciones, descuentos o bonificaciones.",
        },
        {
          value: "3",
          display: "G03 - Gastos en general.",
        },
      ],
    },
    {
      name: "paymentMethod",
      type: "autocomplete",
      label: "Método de pago:",
      options: [
        {
          value: "1",
          display: "PUE - Pago en una sola exhibición",
        },
        {
          value: "2",
          display: "PPD - Pago en parcialidades o diferido",
        },
      ],
    },
    {
      name: "wayToPay",
      type: "autocomplete",
      label: "Forma de pago:",
      options: [
        {
          value: "1",
          display: "1 - Efectivo",
        },
        {
          value: "2",
          display: "2 - Cheque nominativo",
        },
        {
          value: "3",
          display: "3 - Transfrencia eletrónica de fondos",
        },
      ],
    },
    {
      name: "serviceOrder",
      type: "autocomplete",
      label: "Orden de Servicio:",
      options: [
        {
          value: "1",
          display: "1 - S1029",
        },
        {
          value: "2",
          display: "2 - S1030",
        },
        {
          value: "3",
          display: "3 - S1031",
        },
      ],
    },
    {
      name: "export",
      type: "autocomplete",
      label: "Exportación:",
      options: [
        {
          value: "1",
          display: "01 - No aplica",
        },
        {
          value: "2",
          display: "02 - Definitiva",
        },
        {
          value: "3",
          display: "03 - Temporal",
        },
      ],
    },
    {
      name: "date",
      type: "autocomplete",
      label: "Fecha Emisión:",
      options: [
        {
          value: "1",
          display: "27/05/2023",
        },
        {
          value: "2",
          display: "26/05/2023",
        },
        {
          value: "3",
          display: "25/05/2023",
        },
        {
          value: "4",
          display: "24/05/2023",
        },
      ],
    },
  ],
  Section_3: [
    {
      name: "title",
      type: "text",
      label: "Título (Opcional):",
    },
  ],
  Section_4: [
    {
      name: "provider",
      type: "text",
      label: "Proveedor:",
    },
    {
      name: "numberProvider",
      type: "text",
      label: "No. Proveedor.:",
    },
  ],
  Section_5: [
    {
      name: "onlyGenerateXML",
      type: "check",
      label: "Solo Generar XML",
    },
    {
      name: "unitTest",
      type: "check",
      label: "Prueba Unitaria",
    },
  ],
  Section_6: [
    {
      name: "SubTotal",
      type: "text",
      label: "Subtotal",
    },
    {
      name: "Descuento",
      type: "text",
      label: "- Descuento",
    },
    {
      name: "IVA",
      type: "text",
      label: "+ I.V.A",
    },
    {
      name: "IEPS",
      type: "text",
      label: "+ I.E.P.S.",
    },
    {
      name: "RetIVa",
      type: "text",
      label: "- Ret. I.V.A.",
    },
    {
      name: "RetISR",
      type: "text",
      label: "- Ret. I.S.R.",
    },
    {
      name: "Total_ProductosServicios",
      type: "text",
      label: "Total",
    },
  ],
  Section_7: [
    {
      name: "ISH",
      type: "select",
      label: "+ ISH:",
      options: [
        {
          value: "0",
          display: "Extento",
        },
        {
          value: "1",
          display: "2 %",
        },
      ],
    },
    {
      name: "ISHFactor",
      type: "text",
      label: "Factor",
    },
    {
      name: "ISN",
      type: "select",
      label: "- ISN:",
      options: [
        {
          value: "0",
          display: "Extento",
        },
        {
          value: "1",
          display: "2 %",
        },
      ],
    },
    {
      name: "ISNFactor",
      type: "text",
      label: "Factor",
    },
    {
      name: "empty_impuestosLocales",
      type: "simpleLabel",
      label: "",
    },
    {
      name: "Total_ImpuestosLocales",
      type: "text",
      label: "Total",
    },
  ],
  Section_8: [
    {
      name: "periodicidad",
      type: "select",
      label: "Periodicidad:",
      options: [
        {
          value: "0",
          display: "01 - Diario",
        },
        {
          value: "1",
          display: "02 - Semanal",
        },
        {
          value: "1",
          display: "03 - Quincenal",
        },
      ],
    },
    {
      name: "meses",
      type: "select",
      label: "Meses",
      options: [
        {
          value: "1",
          display: "01 -Enero",
        },
        {
          value: "2",
          display: "02 - Febrero",
        },
        {
          value: "3",
          display: "03 - Marzo",
        },
        {
          value: "4",
          display: "04 - Abril",
        },
      ],
    },
    {
      name: "Anio",
      type: "text",
      label: "Año",
    },
  ],
  Section_9: [
    {
      name: "firma_1",
      type: "text",
      label: "Firma 1",
    },
    {
      name: "firma_2",
      type: "text",
      label: "Firma 2",
    },
    {
      name: "firma_3",
      type: "text",
      label: "Firma 3",
    },
  ],
  Section_10: [
    {
      name: "firma_4",
      type: "text",
      label: "Firma 4",
    },
    {
      name: "firma_5",
      type: "text",
      label: "Firma 5",
    },
  ],
  Section_11: [
    {
      name: "tipoRelacion_RelacionarFactura",
      type: "select",
      label: "Tipo de Relación:",
      options: [
        {
          value: "1",
          display: "1 - Nota de c´redito de los docuemtnos relacionadas",
        },
        {
          value: "2",
          display: "2 - Nota de débito de los documentos relacionado",
        },
        {
          value: "3",
          display:
            "3 - Devolución de mercancía sobre facturas o traslados previos",
        },
      ],
    },
    {
      name: "busqueda_Folio_Seria_UUID_RelacionarFactura",
      type: "autocomplete",
      label: "Folio, serie o UUID:",
      options: [],
    },
  ],
  Section_12: [
    {
      name: "facturasRelacionadas_RelacionarFactura",
      type: "text",
      label: "",
    },
  ],
  Section_13: [
    {
      name: "observaciones_datoAdicional",
      type: "text",
      label:
        "Aquí puede ingresar alguna observación o dato adicional de su factura",
    },
    {
      name: "observaciones_politicas_Cotizacion",
      type: "text",
      label: "Aquí puede ingresar las políticas adicionales a su cotización",
    },
  ],
  Section_14: [
    {
      name: "complementoLiverpool_NumeroPedido",
      type: "text",
      label: "Número de Pedido",
    },
    {
      name: "complementoLiverpool_FechaPedido",
      type: "text",
      label: "Fecha de Pedido",
    },
    {
      name: "complementoLiverpool_IdReferencia",
      type: "select",
      options: [
        { value: "DQ", display: "DQ - Folio de Mercancias" },
        { value: "AT", display: "AT - Número de Aprobación" },
        { value: "IV", display: "IV - Número de Factura" },
      ],
      label: "Identificador de Referencia",
    },
    {
      name: "complementoLiverpool_NumeroReferencia",
      type: "text",
      label: "Número de Referencia",
    },
    {
      name: "complementoLiverpool_NumeroContraRecibo",
      type: "text",
      label: "Número de Contra-Recibo",
    },
    {
      name: "complementoLiverpool_FechaContraRecibo",
      type: "text",
      label: "Fecha de Contra-Recibo",
    },
    {
      name: "complementoLiverpool_GLN_Liverpool",
      type: "text",
      label: "GLN Liverpool",
    },
    {
      name: "complementoLiverpool_Departamento_Contacto",
      type: "text",
      label: "Departamento / Contacto",
    },
    {
      name: "complementoLiverpool_GLN_Proveedor",
      type: "text",
      label: "GLN Proveedor",
    },
    {
      name: "complementoLiverpool_NumeroProveedor",
      type: "text",
      label: "Número de Proveedor",
    },
  ],
};

export const dataMock = {
  client: "",
};
