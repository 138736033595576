import { IProperty } from "../../../Components/inputs/TableInput/index.component";
import { ITransmitterFolio } from "../interfaces/TransmitterFolio.interface";

export const _Headers: string[] = [
  "ID",
  "Serie",
  "Folio",
  "Sucursal",
  "Comprobante",
  "Régimen Fiscal",
  "Logotipo",
];

export const _Properties: IProperty[] = [
  { name: "ID", type: "text" },
  { name: "Serie", type: "text" },
  { name: "branch", type: "text" },
  { name: "Folio", type: "text" },
  { name: "Voucher", type: "text" },
  { name: "TaxRegime", type: "text" },
  { name: "Logo", type: "image" },
];

export const dataMock: ITransmitterFolio[] = [
  {
    ID: "1",
    Serie: "S",
    Folio: "4633 -> [1-1000]",
    branch: "TUXTLA GUTIERREZ, CHIAPAS",
    Voucher: "ORDEN DE SERVICIO",
    TaxRegime: "",
    Logo: "https://sistema.comprobanet.com/empresas/VIKO_CUPV8410106U1/logotipos/logoVikonet2.jpg",
  },
  {
    ID: "",
    Serie: "V",
    Folio: "4086 -> [1-10000]",
    branch: "TUXTLA GUTIERREZ, CHIAPAS",
    Voucher: "FACTURA",
    TaxRegime: "Personas Físicas con Actividades Empresariales y Profesionales",
    Logo: "https://sistema.comprobanet.com/empresas/VIKO_CUPV8410106U1/logotipos/logoVikonet2.jpg",
  },
];
