import { Grid } from "@mui/material";
import { LayoutPage } from "../../Components/pages/layoutPage.component";
import FormTitle from "../../Components/pages/formsTitles.component";
import { useBillPage } from "./Hooks/useBillPage";

const BillPage = () => {
  const billPageHook = useBillPage();

  return (
    <LayoutPage>
      <>
        <Grid container item justifyContent={"flex-start"}>
          <FormTitle
            Title={billPageHook.title}
            mode={billPageHook.modeForm}
            hideRefreshButton
            handleNew={billPageHook.handleNew}
            handleSave={billPageHook.handleSave}
            handleCancel={billPageHook.handleCancel}
          />
        </Grid>
        {billPageHook.Form}
      </>
    </LayoutPage>
  );
};

export default BillPage;
