import axios, { AxiosRequestConfig } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setLoading } from "../../../store/modules/loading";
import { resetUser } from "../../../store/modules/user";

export interface IMainResponse<I> {
  code: string;
  internalCode: string | null;
  message: string;
  data: I | null;
}

export interface IRequestPayload {
  path: string;
  method: string;
  // headers?: {
  //   [key: string]: string;
  // };
}

export const INTERNAL_MESSAGES = {
  SUCCESSFUL: "Consulta Exitosa",
  UNKNOWN_ERROR_MESSAGE: "Error desconocido",
  ERROR_MESSAGE:
    "Por el momento los servicios no se encuentran disponibles. Intenta más tarde",
};

export const useApiRequest = <ParamsType, ResponseType>(
  { path, method }: IRequestPayload,
  immediate = false
) => {
  const [status, setStatus] = useState<"pending" | "success" | "error">(
    "pending"
  );
  const [statusRequest, setStatusRequest] = useState<number>();
  const [value, setValue] = useState<IMainResponse<ResponseType> | null>(null);
  const [totalRows, setTotalRows] = useState<number | null>(null);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.users);

  const getTokenAuth = (): string => {
    const token = user.token;
    if (token) {
      return `Bearer ${token}`;
    }
    return "";
  };

  const execute = useCallback(async (params?: ParamsType, pathPag?: string) => {
    setStatus("pending");
    setValue(null);
    try {
      const pathFlag = pathPag ? path + pathPag : path;
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL2}/${pathFlag}`,
        method: method,
        // headers: {
        //   "Content-Type": "application/json",
        //   Accept: "*/*",
        //   Authorization: "", //getTokenAuth(),
        //   ...headers,
        // },
        // data: {
        //   ...params,
        // },
      } as AxiosRequestConfig);
      setTotalRows(Number(response.data.totalRegistros || 0));
      setStatusRequest(response.status);
      setValue(response.data);
      setStatus("success");
    } catch (error: any) {
      if (error?.response?.statusText === "Unauthorized") {
        dispatch(resetUser());
      } else if (error?.response?.data) {
        setValue(error?.response?.data);
      } else {
        setValue({
          message: INTERNAL_MESSAGES.ERROR_MESSAGE,
          data: null,
          code: "",
          internalCode: "",
        });
      }
      setTotalRows(0);
      setStatus("error");
      dispatch(setLoading({ loading: false }));
    }
  }, []);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, status, value, totalRows, statusRequest };
};

// import { useState } from "react";

// interface Props {
// 	method: 'GET'|'POST'| 'DELETE'|'PUT'	| 'PATCH';
// 	path:string;
// }

// interface paramsProps {
// 	params:
// }
// const useApiRequest = ({method, path}: Props) => {
// 	const [_method, setMethod] = useState(method);
// 	const [_path, setPath] = useState(path);
// const [value, setValue] = useState<any|undefined>(undefined);
// const [status, setStatus] = useState<''|'pending'|'error'| 'success'>('');

// const excute = () => {}

// return {execute}

// }
