import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

interface Props {
  label: string;
  name: string;
  disabled?: boolean;
  value: boolean;
  change: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxInput = ({ label, name, value, change, disabled }: Props) => {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox checked={value} onChange={change} />}
          label={label}
        />
      </FormGroup>
    </>
  );
};

export default CheckboxInput;
