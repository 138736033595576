import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import DynamicInputs from "../../../Components/inputs/DynamicInputs/index.component";
import {
  dataInfo,
  inputsCreateJSON_0,
  inputsCreateJSON_1,
  inputsCreateJSON_2,
  inputsCreateJSON_3,
} from "../utils";
import FormTitle, {
  modeFormType,
} from "../../../Components/pages/formsTitles.component";

interface Props {}

const Project_Create = ({}: Props) => {
  const title = "";
  const [data, setData] = useState<dataInfo>({} as dataInfo);
  const [modeForm, setModeForm] = useState<modeFormType>("edition");

  const handleNew = () => {
    //setModeForm("edition");
  };
  const handleSave = () => {
    //setModeForm("consult");
  };
  const handleCancel = () => {
    //setModeForm("consult");
  };

  return (
    <>
      <Grid className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <Grid container item className="m-10" gap={1} justifyContent={"center"}>
          <Grid container item justifyContent={"flex-start"}>
            {/* <h3>{title}</h3> */}
            <FormTitle
              Title={title}
              mode={modeForm}
              hideRefreshButton
              handleNew={handleNew}
              handleSave={handleSave}
              handleCancel={handleCancel}
            />
          </Grid>
          <Grid container item justifyContent={"flex-start"}>
            <DynamicInputs
              inputs={inputsCreateJSON_0}
              columns={4}
              data={data}
              setData={setData}
              mode={modeForm}
            />
          </Grid>
          <Grid container item justifyContent={"flex-start"}>
            <DynamicInputs
              inputs={inputsCreateJSON_1}
              columns={2}
              data={data}
              setData={setData}
              mode={modeForm}
            />
          </Grid>
          <Grid container item justifyContent={"flex-end"}>
            <Grid container item xs={5.5}>
              <DynamicInputs
                inputs={inputsCreateJSON_2}
                columns={1}
                data={data}
                setData={setData}
                mode={modeForm}
              />
            </Grid>
          </Grid>
          <Grid container item justifyContent={"flex-end"}>
            <DynamicInputs
              inputs={inputsCreateJSON_3}
              columns={1}
              data={data}
              setData={setData}
              mode={modeForm}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Project_Create;
