import { useCallback, useEffect, useState } from "react";
import {
  INTERNAL_MESSAGES,
  useApiRequest,
} from "../../../Hooks/ApiRequest/ApiRequest.component";
import { IReceiver } from "../../../interfaces/receivers.interface";

export const useReceiver = <Request, response>() => {
  const [response, setResponse] = useState<any[]>([]);
  const [msgError, setMsgError] = useState<string>("");

  const Client = useApiRequest<
    {},
    { rowCount: number; page: number; data: IReceiver[] }
  >({ path: "receivers", method: "GET" });

  const Get = useCallback(() => {
    Client.execute();
  }, []);

  useEffect(() => {
    if (Client.status === "success" || Client.status === "error") {
      setMsgError(
        Client.status === "success"
          ? ""
          : Client.value?.message || INTERNAL_MESSAGES.ERROR_MESSAGE
      );

      if (Client.status === "success") {
        setResponse(ConvertData(Client.value?.data?.data as IReceiver[]));
      }
    }
  }, [Client.status]);

  function ConvertData(data: IReceiver[]): any {
    const result: any[] = data;

    return result;
  }

  return { Get, response, msgError };
};
