import { useEffect, useState } from "react";
import TableInput from "../../Components/inputs/TableInput/index.component";
import { useReceiver } from "./hooks/useReceiver";
import { Headers, Properties } from "./util";
import CustomModal from "../../Components/Modal/index.componente";
import Receiver_Create from "./components/create.component";

const Receivers = () => {
  const Title: string = "Receptores";
  const receiverHook = useReceiver();
  const [data, setData] = useState<any[]>([]);
  const [receiverSelected, setReceiverSelected] = useState<any | undefined>(
    undefined
  );
  const [tittleModal, setTittleModal] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleNew = () => {
    //navigate("/serviceOrder/create");
    setReceiverSelected(undefined);
    setShowModal(true);
    setTittleModal("Nueva Receptor");
  };
  const handleEdit = (e: any) => {
    const _Selected = receiverHook.response.filter((x: any) => x.ID === e);

    setReceiverSelected(_Selected[0] || undefined);
    setShowModal(true);
    setTittleModal("Editar Receptor");
  };

  useEffect(() => {
    receiverHook.Get();
  }, []);
  useEffect(() => {
    if (
      receiverHook.response === undefined ||
      receiverHook.response.length === 0
    ) {
      return;
    }

    setData(receiverHook.response);
  }, [receiverHook.response]);
  return (
    <>
      <TableInput
        Title={Title}
        headers={Headers}
        properties={Properties}
        data={data}
        handleNew={handleNew}
        handleEdit={(e: any) => handleEdit(e)}
        handleRefresh={() => receiverHook.Get()}
      />
      <CustomModal
        Title={tittleModal}
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        width={600}
      >
        <Receiver_Create />
      </CustomModal>
    </>
  );
};

export default Receivers;
