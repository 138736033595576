import { Grid } from "@mui/material";
import { useINEComplements } from "../Hooks/useINEComplements";
import DynamicInputs from "../../../Components/inputs/DynamicInputs/index.component";

interface Props {
  setData: React.Dispatch<any>;
  data: any;
}

const INEComplements = ({ setData, data }: Props) => {
  const useINEComplementsHook = useINEComplements(setData, data);

  return (
    <Grid container item>
      <DynamicInputs
        inputs={useINEComplementsHook.inputSelect}
        columns={4}
        data={useINEComplementsHook.dataComponent}
        setData={useINEComplementsHook.setDataCompoenent}
        mode={"edition"}
      />
    </Grid>
  );
};

export default INEComplements;
