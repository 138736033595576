import { useNavigate } from "react-router-dom";
import TableInput from "../../Components/inputs/TableInput/index.component";
import { useServiceOrder } from "./hooks/useServiceOrders";
import { useEffect, useState } from "react";
import { Properties, Headers } from "./utils";
import { IServiceOrder } from "../../interfaces/serviceOrder.interface";
import CustomModal from "../../Components/Modal/index.componente";
import Project_Create from "./components/create.component";

const ServiceOrdersPage = () => {
  const Title: string = "Ordenes de Servicio";
  const serviceOrderHook = useServiceOrder();
  const [data, setData] = useState<any[]>([]);
  const [orderSelected, setOrderSelected] = useState<IServiceOrder | undefined>(
    undefined
  );
  const [tittleModal, setTittleModal] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    serviceOrderHook.Get();
  }, []);
  useEffect(() => {
    if (
      serviceOrderHook.response === undefined ||
      serviceOrderHook.response.length === 0
    ) {
      return;
    }

    setData(serviceOrderHook.response);
  }, [serviceOrderHook.response]);

  const handleNew = () => {
    //navigate("/serviceOrder/create");
    setOrderSelected(undefined);
    setShowModal(true);
    setTittleModal("Nueva Orden");
  };
  const handleEdit = (e: any) => {
    const _Selected = serviceOrderHook.response.filter((x: any) => x.ID === e);

    setOrderSelected(_Selected[0] || undefined);
    setShowModal(true);
    setTittleModal("Editar Orden");
  };
  return (
    <>
      <TableInput
        Title={Title}
        headers={Headers}
        properties={Properties}
        data={data}
        handleNew={handleNew}
        handleEdit={(e: any) => handleEdit(e)}
        handleRefresh={() => serviceOrderHook.Get()}
      />
      <CustomModal
        Title={tittleModal}
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        width={600}
      >
        <Project_Create />
      </CustomModal>
    </>
  );
};

export default ServiceOrdersPage;
