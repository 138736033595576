import { Grid } from "@mui/material";
import TextInput from "../../TextInput/index.component";
import { useState } from "react";

interface Props {
  name: string;
}

const TableInputSearch = ({ name }: Props) => {
  const [data, setData] = useState<string>("");

  return (
    <Grid container item>
      <TextInput
        label={"Búsqueda"}
        name={`Busqueda_${name}`}
        value={data}
        change={(value) => setData(value)}
        alertMessage={""}
      />
    </Grid>
  );
};

export default TableInputSearch;
