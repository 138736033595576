export interface IUser {
  ID: string;
  User: string;
  Name: string;
  SystemId: number;
  System?: string;
  BD: string;
  Modules: string[];
  Stamps: number;
  UserStatus: string;
  Validity: string;
  LastPurchase?: Date;
  Days: number;
  Password?: string;
  Email?: string;
  TypeUser?: string;
}

export const DefaultValueUser: IUser = {
  ID: "",
  User: "",
  Name: "",
  SystemId: -1,
  BD: "",
  Modules: [],
  Stamps: -1,
  UserStatus: "",
  Validity: "",
  // LastPurchase: Date;
  Days: -1,
};
