import { Grid } from "@mui/material";
import TabInput from "../../Components/inputs/TabInput";
import { useState } from "react";
import { ITabInput_Item } from "../../interfaces/TabInput/useTabInput.interface";
import TaxData from "./components/TaxData.component";
import DigitalStamp from "./components/DigitalStamp.component";
import BranchOffice from "./components/BranchOffice.componet";
import TransmitterFolio from "./components/TransmitterFolios.component";

const TransmitterConfig = () => {
  const tabs: ITabInput_Item[] = [
    { tabName: "Datos Fiscales", body: <TaxData title="Datos Fiscales" /> },
    { tabName: "Sello Digital", body: <DigitalStamp /> },
    { tabName: "Sucursales", body: <BranchOffice /> },
    { tabName: "Folios", body: <TransmitterFolio /> },
  ];

  const [tab, setTab] = useState<string>("");

  return (
    <Grid className="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <Grid className="card-body p-9">
        <TabInput tabs={tabs} setTabSelected={(e) => setTab(e)} />
      </Grid>
    </Grid>
  );
};

export default TransmitterConfig;
