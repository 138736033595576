import { useState } from "react";
import clsx from "clsx";
import { ITabInput_Item } from "../../../interfaces/TabInput/useTabInput.interface";
import { useTabInput } from "../../../Hooks/TabInput/useTabInput";

interface Props {
  tabs: ITabInput_Item[];
  setTabSelected: React.Dispatch<React.SetStateAction<string>>;
}

const TabInput = ({ tabs }: Props) => {
  const tabInputHook = useTabInput(tabs);

  return (
    <>
      <div className="card card-custom">
        <div className="card-header card-header-stretch overflow-auto">
          <ul
            className="nav nav-stretch nav-line-tabs
		  fw-bold
		  border-transparent
		  flex-nowrap
          "
            role="tablist"
          >
            {tabs.map((_tab, index) => (
              <li
                className="nav-item"
                key={`${_tab.tabName.replaceAll(" ", "_")}_${index}`}
              >
                <a
                  className={clsx(`nav-link cursor-pointer`, {
                    active: tabInputHook.tab === _tab.tabName,
                  })}
                  onClick={() => tabInputHook.setTab(_tab.tabName)}
                  role="tab"
                >
                  {_tab.tabName}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <form className="form">
        <div className="card-body">{tabInputHook.body}</div>
      </form>
    </>
  );
};

export default TabInput;
