import { Autocomplete, TextField } from "@mui/material";
import { IOption } from "../SelectInput/index.component";
import { useEffect, useState } from "react";

interface Props {
  label: string;
  name: string;
  options: IOption[];
  value: any;
  change: (value: any) => void;
  disable?: boolean;
}

export default function MultiSelectInput({
  label,
  name,
  value,
  change,
  options,
  disable,
}: Props) {
  const [_Options, set_Options] = useState<string[]>([]);

  useEffect(() => {
    const newOptions: string[] = [];
    options.forEach((x) => {
      newOptions.push(x.value);
    });

    set_Options(newOptions);
  }, [options]);

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      disabled={disable}
      multiple
      value={value}
      onChange={(event: any, newValue: any[] | null) => {
        change(newValue);
      }}
      options={_Options}
      getOptionLabel={(option) => option}
      disableCloseOnSelect
      id={name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          //   placeholder={label}
        />
      )}
    />
  );
}
