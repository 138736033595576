import TableInput from "../../../Components/inputs/TableInput/index.component";
import { useTransmitterFolios } from "../hooks/useTransmitterFolios";

const TransmitterFolio = () => {
  const useTransmitterFoliosHook = useTransmitterFolios();

  return (
    <TableInput
      Title={useTransmitterFoliosHook.title}
      headers={useTransmitterFoliosHook.headers}
      properties={useTransmitterFoliosHook.properties}
      data={useTransmitterFoliosHook.data}
      handleNew={useTransmitterFoliosHook.handeNew()}
      handleEdit={(e: any) => useTransmitterFoliosHook.handeEdit(e)}
      handleRefresh={useTransmitterFoliosHook.handeRefresh()}
    />
  );
};

export default TransmitterFolio;
