import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AutorenewIcon from "@mui/icons-material/Autorenew";

interface Props {
  handleNew: (value: any) => void;
  handleRefresh: (value: any) => void;
  hiddenNewButton?: boolean;
  hiddenRefreshButton?: boolean;
}

const TableInput_buttons = ({
  handleNew,
  handleRefresh,
  hiddenNewButton = false,
  hiddenRefreshButton = false,
}: Props) => {
  return (
    <Grid
      container
      item
      justifyContent={"right"}
      gap={1}
      style={{ width: "100%" }}
    >
      <Grid item>
        {hiddenNewButton !== true && (
          <Button variant="contained" onClick={(e) => handleNew(e)}>
            <AddIcon />
            Nuevo
          </Button>
        )}
      </Grid>
      <Grid item>
        {hiddenRefreshButton !== true && (
          <Button variant="contained" onClick={(e) => handleRefresh(e)}>
            <AutorenewIcon />
            Actualizar
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default TableInput_buttons;
