import { useState } from "react";
import { modeFormType } from "../../../Components/pages/formsTitles.component";
import { useBillPageForm } from "./useBillPageForm";
import { dataMock, inputsForm } from "../utils/Bill.mock";

export const useBillPage = () => {
  const [title, setTitle] = useState("Factura 4.0");
  const [modeForm, setModeForm] = useState<modeFormType>("edition");
  const [data, setData] = useState(dataMock);

  const { Form } = useBillPageForm(modeForm, setData, data, inputsForm);

  const handleNew = () => {};
  const handleSave = () => {};
  const handleCancel = () => {};

  return {
    title,
    modeForm,
    setModeForm,
    handleNew,
    handleSave,
    handleCancel,
    Form,
  };
};
